import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

const LoadingProgress = ({ loading = true }) => {
  const [initialLoading, setInitialLoading] = useState(loading)
  const [progress, setProgress] = useState(0)
  const router = useRouter()

  useEffect(() => {
    let to

    const handleStart = (url) => {
      setProgress(70)
      url !== router.pathname ? setInitialLoading(true) : setInitialLoading(false)
    }

    const handleComplete = (url) => {
      setInitialLoading(false)
      setProgress(100)
      to = setTimeout(() => {
        setProgress(0)
      }, 500)
    }

    const handleError = (err, url) => {
      if (err.cancelled) {
        console.warn(`Route to ${url} was cancelled!`)
      }

      handleComplete(url)
    }

    router.events.on("routeChangeStart", handleStart)
    router.events.on("routeChangeComplete", handleComplete)
    router.events.on("routeChangeError", handleError)

    window.addEventListener("load", handleComplete)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleError)

      window.removeEventListener("load", handleComplete)
      clearTimeout(to)
    }
  }, [router.events, router.pathname])

  return (
    <div style={{ width: `${progress}%`, transition: `${initialLoading ? 'width 1s linear' : ''}` }} className="fixed top-0 left-0 h-1 w-1 flex items-center justify-center z-50 bg-olive-500" />
  )
}

export default LoadingProgress